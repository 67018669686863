"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./img/*.{png,jpg,jpeg,gif,svg}";

// import stylu
import "../index.scss";

require("fslightbox");

// SmoothScroll inicializace
import SmoothScroll from "smooth-scroll";
new SmoothScroll('a[href*="#"]', {
	header: "header",
	speed: 300,
	speedAsDuration: true,
});

// NodeList.prototype.forEach() polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

// clickToggleClass - BEGIN
window.addEventListener("DOMContentLoaded", () => {
	document.querySelectorAll("*[data-click-toggle-class]").forEach((elm) => {
		elm.addEventListener("click", (event) => {
			event.preventDefault();

			const classes = event.currentTarget.dataset.clickToggleClass.split("|");
			const targets = event.currentTarget.dataset.clickToggleTarget
				? [...document.querySelectorAll(event.target.dataset.clickToggleTarget)]
				: [event.target];
			const toggledClases = targets[0].classList.contains(classes[0])
				? {
						remove: classes[0],
						add: classes[1],
				  }
				: {
						remove: classes[1],
						add: classes[0],
				  };
			targets.forEach((elm) => {
				elm.classList.remove(toggledClases.remove);
				elm.classList.add(toggledClases.add);
			});
		});
	});
});
// clickToggleClass - END
//reg function
/*
window.addEventListener("DOMContentLoaded", () => {
	const btnAccept = document.getElementById("btnAccept");
	const agreeSection = document.getElementById("agreeSection");
	const accept = document.getElementById("accept");
	btnAccept.addEventListener("click", (e) => {
		e.preventDefault();		
		agreeSection.classList.remove("hidden");
		accept.value = 1;
	});
	
});
*/


